import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            background: 'linear-gradient(to bottom right, #ff9a9e, #fad0c4, #ffecd2)',
            padding: '1rem',
            color: 'white',
            position: 'relative',
            overflow: 'hidden',
        },
        content: {
            zIndex: 10,
            width: '100%',
            maxWidth: '24rem',
            textAlign: 'center',
        },
        title: {
            fontSize: '6rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
            color: 'rgba(255, 255, 255, 0.8)',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
        },
        subtitle: {
            fontSize: '1.5rem',
            fontWeight: 'bold',
            marginBottom: '2rem',
            color: '#4a1d96',
        },
        description: {
            fontSize: '1rem',
            marginBottom: '2rem',
            color: '#4a1d96',
        },
        button: {
            width: '100%',
            backgroundColor: '#4a1d96',
            color: 'white',
            fontWeight: 'bold',
            padding: '1rem 1.5rem',
            borderRadius: '9999px',
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
            transition: 'all 300ms ease-in-out',
            display: 'inline-block',
            textDecoration: 'none',
            textAlign: 'center',
            cursor: 'pointer',
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.content}>
                <h1 style={styles.title}>404</h1>
                <h2 style={styles.subtitle}>Oops! Page not found</h2>
            </div>
        </div>
    );
};

export default NotFoundPage;