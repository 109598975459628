import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import axios from "axios";

const RedirectTimerPage = () => {
    const [timeLeft, setTimeLeft] = useState(3);
    const [statusMessage, setStatusMessage] = useState("");
    const { id } = useParams();
    const location = useLocation();
    const onlyUrl = new URLSearchParams(location.search).get('onlyUrl');
    const targetUrl = `https://${onlyUrl}`;

    useEffect(() => {
        incrementClicks();
        if (timeLeft > 0) {
            const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            return () => clearTimeout(timerId);
        } else {
            openLink();
        }
    }, [timeLeft, targetUrl]);
    const incrementClicks = async () => {
        try {
            await axios.post(`/api/models/${id}/incrementButtonClicks`);
        } catch (error) {
        }
    };
    const updateStatus = (message) => {
        setStatusMessage(message);
    };

    const openLink = () => {
        updateStatus("Detecting the device and trying to open the link...");
        window.location.href = targetUrl;
    };

    return (
        <div style={styles.container}>
            <div style={styles.content}>
                <h1 style={styles.title}>Redirect</h1>
                {timeLeft > 0 ? (
                    <>
                        <div style={styles.timer}>{timeLeft}</div>
                    </>
                ) : (
                    <p style={styles.statusMessage}>{statusMessage}</p>
                )}
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: '#FFF0F5',
        fontFamily: 'Arial, sans-serif',
        padding: '20px',
    },
    content: {
        textAlign: 'center',
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
        padding: '30px',
        boxShadow: '0 10px 25px rgba(255, 182, 193, 0.5)',
    },
    title: {
        color: '#FF69B4',
        fontSize: '28px',
        marginBottom: '20px',
    },
    timer: {
        fontSize: '72px',
        fontWeight: 'bold',
        color: '#FF1493',
        margin: '20px 0',
    },
    text: {
        fontSize: '18px',
        color: '#DB7093',
        marginBottom: '20px',
    },
    statusMessage: {
        fontSize: '16px',
        color: '#C71585',
        lineHeight: '1.5',
    },
};

export default RedirectTimerPage;