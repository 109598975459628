import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(''); // Очищаємо попередні помилки
        try {
            console.log('Спроба входу для користувача:', username);
            const response = await axios.post('http://localhost:5000/api/login', { username, password });
            console.log('Відповідь сервера:', response);
            if (response.data && response.data.token) {
                console.log('Токен отримано');
                localStorage.setItem('token', response.data.token);
                navigate('/admin/dashboard');
            } else {
                console.error('Токен не отримано в відповіді');
                setError('Неправильна відповідь сервера');
            }
        } catch (err) {
            console.error('Помилка при вході:', err);
            if (err.response) {
                console.error('Відповідь сервера:', err.response.data);
                setError(`Помилка: ${err.response.data.message || 'Невідома помилка сервера'}`);
            } else if (err.request) {
                console.error('Немає відповіді від сервера');
            } else {
                console.error('Помилка налаштування запиту:', err.message);
                setError(`Помилка: ${err.message}`);
            }
        }
    };

    return (
        <div className="login-container">
            <div className="login-card">
                <h2>Admin Login</h2>
                <form onSubmit={handleLogin}>
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    <button type="submit" className="login-button">
                        Login
                    </button>
                </form>
            </div>
            <style jsx>{`
                .login-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 100vh;
                    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
                    font-family: 'Arial', sans-serif;
                }
                .login-card {
                    background: rgba(255, 255, 255, 0.9);
                    padding: 2rem;
                    border-radius: 10px;
                    box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
                    backdrop-filter: blur(4px);
                    border: 1px solid rgba(255, 255, 255, 0.18);
                    width: 100%;
                    max-width: 400px;
                }
                h2 {
                    color: #4a5568;
                    text-align: center;
                    margin-bottom: 1.5rem;
                    font-size: 2rem;
                }
                .form-group {
                    margin-bottom: 1.5rem;
                }
                label {
                    display: block;
                    margin-bottom: 0.5rem;
                    color: #4a5568;
                    font-weight: bold;
                }
                input {
                    width: 100%;
                    padding: 0.75rem;
                    border: 2px solid #e2e8f0;
                    border-radius: 5px;
                    font-size: 1rem;
                    transition: border-color 0.3s ease;
                }
                input:focus {
                    outline: none;
                    border-color: #667eea;
                }
                .login-button {
                    width: 100%;
                    padding: 0.75rem;
                    background-color: #667eea;
                    color: white;
                    border: none;
                    border-radius: 5px;
                    font-size: 1rem;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                }
                .login-button:hover {
                    background-color: #5a67d8;
                }
                .error-message {
                    background-color: #fed7d7;
                    border: 1px solid #f56565;
                    color: #c53030;
                    padding: 0.5rem;
                    border-radius: 5px;
                    margin-bottom: 1rem;
                    text-align: center;
                }
            `}</style>
        </div>
    );
};

export default LoginPage;