import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Container, Row, Col, Card, Image } from 'react-bootstrap';
import axios from 'axios';

const AdminDashboard = () => {
    const [show, setShow] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [models, setModels] = useState([]);
    const [formData, setFormData] = useState({
        modelName: '',
        modelUrl: '',
        clicks: 0,
        buttonClicks: 0,
        onlyUrl: '',
        image: null
    });

    useEffect(() => {
        fetchModels();
    }, []);

    const fetchModels = async () => {
        const response = await axios.get('/api/models');
        setModels(response.data);
    };

    const handleClose = () => {
        setShow(false);
        setEditIndex(null);
        setFormData({ modelName: '', modelUrl: '', clicks: 0, buttonClicks: 0, onlyUrl: '', image: null });
    };

    const handleShow = () => setShow(true);

    const handleSave = async () => {
        const data = new FormData();
        for (let key in formData) {
            if (key === 'image' && formData[key] instanceof File) {
                data.append(key, formData[key]);
            } else if (key !== 'image') {
                data.append(key, formData[key]);
            }
        }

        if (editIndex !== null) {
            const updatedModel = await axios.put(`/api/models/${models[editIndex].id}`, data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            const updatedModels = [...models];
            updatedModels[editIndex] = updatedModel.data;
            setModels(updatedModels);
        } else {
            const newModel = await axios.post('/api/models', data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            setModels([...models, newModel.data]);
        }
        handleClose();
    };

    const handleEdit = (index) => {
        setEditIndex(index);
        setFormData({...models[index], image: null});
        handleShow();
    };

    const handleDelete = async (index) => {
        const modelId = models[index].id;

        try {
            await axios.delete(`/api/models/${modelId}`);
            const updatedModels = models.filter((_, i) => i !== index);
            setModels(updatedModels);
        } catch (error) {
            console.error('Error during delete operation:', error);
        }
    };

    const handleImageChange = (e) => {
        setFormData({...formData, image: e.target.files[0]});
    };

    return (
        <div className="admin-dashboard">
            <Container fluid className="py-5">
                <Row className="justify-content-center">
                    <Col md={10}>
                        <Card className="main-card">
                            <Card.Body>
                                <h1 className="dashboard-title">TikTok Admin Panel</h1>
                                <div className="action-bar">
                                    <Button variant="primary" className="add-model-btn" onClick={handleShow}>
                                        Add Model
                                    </Button>
                                    <Form.Control
                                        type="text"
                                        placeholder="Search models..."
                                        className="search-input"
                                    />
                                </div>
                                <Table responsive striped bordered hover className="custom-table">
                                    <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Model Name</th>
                                        <th>Model URL</th>
                                        <th>Clicks</th>
                                        <th>Button Clicks</th>
                                        <th>Only URL</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {models.map((model, index) => (
                                        <tr key={index}>
                                            <td>
                                                <Image
                                                    src={`/api/models/${model.id}/image`}
                                                    alt={model.modelName}
                                                    thumbnail
                                                    style={{width: '50px', height: '50px', objectFit: 'cover'}}
                                                />
                                            </td>
                                            <td>{model.modelName}</td>
                                            <td>{model.modelUrl}</td>
                                            <td>{model.clicks}</td>
                                            <td>{model.buttonClicks}</td>
                                            <td>{model.onlyUrl}</td>
                                            <td>
                                                <Button variant="outline-warning" size="sm" className="action-btn edit-btn" onClick={() => handleEdit(index)}>
                                                    Edit
                                                </Button>
                                                <Button variant="outline-danger" size="sm" className="action-btn delete-btn" onClick={() => handleDelete(index)}>
                                                    Delete
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Modal show={show} onHide={handleClose} className="custom-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>{editIndex !== null ? 'Edit Model' : 'Add Model'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="modelName">
                                <Form.Label>Model Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter model name"
                                    value={formData.modelName}
                                    onChange={(e) => setFormData({ ...formData, modelName: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="modelUrl">
                                <Form.Label>Model URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter model URL"
                                    value={formData.modelUrl}
                                    onChange={(e) => setFormData({ ...formData, modelUrl: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="onlyUrl">
                                <Form.Label>Only URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter only URL"
                                    value={formData.onlyUrl}
                                    onChange={(e) => setFormData({ ...formData, onlyUrl: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="image">
                                <Form.Label>Image</Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={handleImageChange}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSave}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>

            <style jsx>{`
                .admin-dashboard {
                    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
                    min-height: 100vh;
                    font-family: 'Roboto', sans-serif;
                }

                .main-card {
                    background-color: rgba(255, 255, 255, 0.9);
                    border-radius: 15px;
                    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
                    backdrop-filter: blur(5px);
                    border: 1px solid rgba(255, 255, 255, 0.3);
                }

                .dashboard-title {
                    color: #2c3e50;
                    font-weight: 700;
                    margin-bottom: 1.5rem;
                    text-align: center;
                    font-size: 2.5rem;
                }

                .action-bar {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 2rem;
                }

                .add-model-btn {
                    background-color: #3498db;
                    border: none;
                    padding: 0.6rem 1.2rem;
                    font-weight: 600;
                    transition: all 0.3s ease;
                }

                .add-model-btn:hover {
                    background-color: #2980b9;
                    transform: translateY(-2px);
                    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
                }

                .search-input {
                    max-width: 300px;
                    border-radius: 20px;
                    border: 1px solid #bdc3c7;
                    padding: 0.5rem 1rem;
                    transition: all 0.3s ease;
                }

                .search-input:focus {
                    border-color: #3498db;
                    box-shadow: 0 0 0 0.2rem rgba(52, 152, 219, 0.25);
                }

                .custom-table {
                    background-color: white;
                    border-radius: 10px;
                    overflow: hidden;
                }

                .custom-table thead {
                    background-color: #34495e;
                    color: white;
                }

                .custom-table th, .custom-table td {
                    vertical-align: middle;
                }

                .action-btn {
                    margin-right: 0.5rem;
                    border-radius: 20px;
                    transition: all 0.3s ease;
                }

                .edit-btn:hover {
                    background-color: #f39c12;
                    color: white;
                }

                .delete-btn:hover {
                    background-color: #e74c3c;
                    color: white;
                }

                .custom-modal .modal-content {
                    border-radius: 15px;
                    border: none;
                }

                .custom-modal .modal-header {
                    background-color: #3498db;
                    color: white;
                    border-top-left-radius: 15px;
                    border-top-right-radius: 15px;
                }

                .custom-modal .modal-footer {
                    border-top: none;
                }

                .custom-modal .btn-primary {
                    background-color: #3498db;
                    border: none;
                }

                .custom-modal .btn-primary:hover {
                    background-color: #2980b9;
                }

                .custom-modal .form-control {
                    border-radius: 20px;
                    border: 1px solid #bdc3c7;
                    padding: 0.5rem 1rem;
                    transition: all 0.3s ease;
                }

                .custom-modal .form-control:focus {
                    border-color: #3498db;
                    box-shadow: 0 0 0 0.2rem rgba(52, 152, 219, 0.25);
                }
            `}</style>
        </div>
    );
};

export default AdminDashboard;