import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, useParams, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import LoginPage from './pages/admin/LoginPage';
import AdminDashboard from './pages/admin/AdminDashboard';
import ModelRouter from './pages/userui/ModelPage';
import NotFoundPage from './pages/404/404';
import RedirectTimerPage from './redirectcore/page/RedirectTimerPage';
import './styles/userpage.css';

function App() {
    const [models, setModels] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchModels = async () => {
            try {
                const response = await axios.get('/api/models/details');
                setModels(response.data);
            } catch (error) {
            } finally {
                setLoading(false);
            }
        };

        fetchModels();
    }, []);

    const ModelRoute = () => {
        const location = useLocation();

        const baseModelUrl = location.pathname.split('/')[1];

        const model = models.find(m => m.modelUrl === baseModelUrl);

        if (model) {
            return <ModelRouter modelId={model.id} modelUrl={model.modelUrl} onlyUrl={model.onlyUrl} />;
        } else if (loading) {
            return <div>Loading...</div>;
        } else {
            return <Navigate to="/404" />;
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <Routes>
                <Route path="/admin" element={<LoginPage />} />
                <Route path="/admin/dashboard" element={<AdminDashboard />} />
                <Route path="/:modelUrl/*" element={<ModelRoute />} />
                <Route path="/dev/r/:id" element={<RedirectTimerPage />} />
                <Route path="/404" element={<NotFoundPage />} />
                <Route path="*" element={<Navigate to="/404" />} />
            </Routes>
        </Router>
    );
}

export default App;